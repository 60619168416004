<template>
  <div>
    <!-- pic6F0CB43555B4A664B707AD1DD643489B.jpg -->
    <img class="rounded img-large" src="@/assets/news-network/mln-ninja.jpg">
    <p>
      A LEGO Universe ninja leader is sneaking up on My LEGO Network! His name is Numb Chuck, and he’s both cunning and cool-headed as he guides his group of martial warriors!
    </p>
    <p>
      Numb Chuck’s ninjas live and train deep within a secluded valley. A dense forest surrounds the spot where he built his dream dojo after scouring LEGO Universe for the optimal place to perfect his battle skills.
    </p>
    <p>
      Numb Chuck’s senses are so attuned to battle that ordinary events send him straight to sleep—though legend has it that he’s most dangerous when snoozing! To help himself stay alert, Numb Chuck constantly hunts for exciting reading material or someone to spar with. Battle practice partners are especially hard for him to find, for few fighters dare faceoff against this martial arts master!
    </p>
    <p>
      When Numb Chuck needs other ways to amuse himself, he might have fun with his favorite creative hobby: singing karaoke!
    </p>
    <p>
      Numb Chuck says, “You may—Zzzz… *SNORK!!*… visit me soon on My LEGO Network, but you’ll—Zzzz… *SNORK!!*… have to remove your shoes! What? Like my dojo’s some dirty boat?!... KIYAA!!! Zzzz…”
    </p>
  </div>
</template>
